.panel {
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    color: #88a4b3;
    padding: 20px;
    min-width: 250px;
}

.location {
    color: #5f7d6d;
    box-sizing: border-box;
    width: 100%;
    padding: 5px 20px;
}


.value {
    font-size: 2em;
    font-weight: bold;
    margin: 20px 0;
}

.updated {
    color: #8d8fb3;
    font-size: 0.7em;
}

.panel > div > svg {
    margin-right: 10px;
}