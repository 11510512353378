.google-button {
    background: url("assets/btn_google_signin_dark_normal_web.png");
    border: 0;
    width: 191px;
    height: 46px;
}

.google-button:hover {
    background: url("assets/btn_google_signin_dark_focus_web.png");
}

.google-button:active {
    background: url("assets/btn_google_signin_dark_pressed_web.png");
}